import React, { useEffect } from "react";
import kebabCase from "lodash/kebabCase";
import { useStripeCheckout } from "../hooks/useStripeCheckout";
import ReactPlayer from "react-player/lazy";
import photoSmallMe from "../static/me-small.png";
import { classes2022, overview } from "../content/classes";
import Layout from "../components/layout";
import "../tailwind.css";
import "../index.css";
import { Helmet } from "react-helmet";
import { Section } from "../components/Layout/Section";
import { Link as AnchorLink } from "react-scroll";
import { PageTitle } from "../components/Common/PageTitle";
import ReactMarkdown from "react-markdown";
import {
  OnlineAndMaterialBuySection,
  OnlineOnlyBuySection,
} from "../components/BuySections";

const importAll = (r, cache) =>
  r.keys().forEach((key) => (cache[key] = r(key)));
const imageFiles = {};
importAll(
  require.context(`../content/salesPages/`, true, /\.(png|gif|jpg)$/),
  imageFiles,
);
const transformImageUriBuilder = (slug) => (uri) => {
  const imgPath = `./${slug}/${uri.substring(2)}`;
  const importedImg = imageFiles[imgPath];
  return importedImg ? importedImg.default : "";
};

const Divider = ({ text, margins = {} }) => (
  <div
    className={`relative ${margins.b ? "mb-5" : ""} ${
      margins.t ? "mt-10" : ""
    }`}
  >
    <div className="absolute inset-0 flex items-center" aria-hidden="true">
      <div className="w-full border-t border-gray-300"></div>
    </div>
    <div className="relative flex justify-center">
      <span className="px-3 bg-white text-lg font-medium text-gray-900">
        {text}
      </span>
    </div>
  </div>
);

const Spinny = ({
  text = "Creating Order",
  nomt = false,
  extraClasses = "",
}) => (
  <span
    className={`${
      nomt ? " " : "mt-8"
    } ${extraClasses} w-full inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-blue-700 bg-white hover:bg-indigo-50 sm:w-auto`}
  >
    <svg
      className="animate-spin w-6 h-6"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path
        fillRule="evenodd"
        d="M9.504 1.132a1 1 0 01.992 0l1.75 1a1 1 0 11-.992 1.736L10 3.152l-1.254.716a1 1 0 11-.992-1.736l1.75-1zM5.618 4.504a1 1 0 01-.372 1.364L5.016 6l.23.132a1 1 0 11-.992 1.736L4 7.723V8a1 1 0 01-2 0V6a.996.996 0 01.52-.878l1.734-.99a1 1 0 011.364.372zm8.764 0a1 1 0 011.364-.372l1.733.99A1.002 1.002 0 0118 6v2a1 1 0 11-2 0v-.277l-.254.145a1 1 0 11-.992-1.736l.23-.132-.23-.132a1 1 0 01-.372-1.364zm-7 4a1 1 0 011.364-.372L10 8.848l1.254-.716a1 1 0 11.992 1.736L11 10.58V12a1 1 0 11-2 0v-1.42l-1.246-.712a1 1 0 01-.372-1.364zM3 11a1 1 0 011 1v1.42l1.246.712a1 1 0 11-.992 1.736l-1.75-1A1 1 0 012 14v-2a1 1 0 011-1zm14 0a1 1 0 011 1v2a1 1 0 01-.504.868l-1.75 1a1 1 0 11-.992-1.736L16 13.42V12a1 1 0 011-1zm-9.618 5.504a1 1 0 011.364-.372l.254.145V16a1 1 0 112 0v.277l.254-.145a1 1 0 11.992 1.736l-1.735.992a.995.995 0 01-1.022 0l-1.735-.992a1 1 0 01-.372-1.364z"
        clipRule="evenodd"
      />
    </svg>
    &nbsp;
    {text}
  </span>
);

const SingleBuySection = ({ product }) => {
  const { loading, redirectToCheckout } = useStripeCheckout(product);
  return (
    <div className=" mt-10 rounded">
      <div className="w-full text-center py-3 px-4">
        {loading ? (
          <Spinny />
        ) : (
          <button
            onClick={redirectToCheckout}
            className="my-8 w-48 mx-auto text-base font-bold text-blue-700 hover:text-blue-800 sm:text-lg cursor-pointer
                  md:text-inline-flex items-center justify-center py-3 border border-transparent rounded-md bg-gray-50 hover:bg-blue-100"
          >
            Buy Class €50
          </button>
        )}
      </div>
    </div>
  );
};

const SingleBuySection2 = ({ product }) => {
  const { loading, redirectToCheckout } = useStripeCheckout(product);
  return (
    <div className="rounded mt-12">
      <div className="w-full text-center px-4">
        <div className="max-w-md mx-auto lg:max-w-2xl border-transparent border rounded-md bg-blue-100">
          <div className="text-center py-8">
            <h2 className="text-3xl tracking-tight text-blue-700 mx-auto">
              <div>Sound good? Then don't wait and</div>
            </h2>
          </div>
          {loading ? (
            <Spinny nomt extraClasses="mb-6" />
          ) : (
            <button
              onClick={redirectToCheckout}
              className="max-w-md mx-auto text-base text-white sm:text-lg md:mt-2 mb-6
                  md:text-inline-flex items-center justify-center px-5 py-3 border border-transparent rounded-md bg-blue-700 hover:bg-blue-500 sm:w-auto"
            >
              Buy Class €50
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

const PriceCard = ({ purchaseables }) => {
  const baseClass = purchaseables.find((it) => it.metadata.type === "class");
  const extendedClass = purchaseables.find(
    (it) => it.metadata.type === "extended_class",
  );
  const { loading: loadingBase, redirectToCheckout: redirectToCheckoutBase } =
    useStripeCheckout(baseClass);
  const {
    loading: loadingExtended,
    redirectToCheckout: redirectToCheckoutExtended,
  } = useStripeCheckout(extendedClass);
  return (
    <div
      id="purchase"
      className="bg-blue-100 mt-16 relative max-w-screen-lg mx-auto px-4 sm:px-6 lg:px-8 rounded-md"
    >
      <div className="max-w-md mx-auto lg:max-w-3xl">
        <div className="text-center py-8">
          <h2 className="text-3xl tracking-tight text-blue-700 mx-auto">
            <div className="mb-6">
              Sound good? Then don't wait and buy the class!
            </div>
            <div>You have 2 options:</div>
          </h2>
        </div>
        <dd className="mt-8 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          <ul
            role="list"
            className="border border-gray-500 rounded-md divide-y divide-gray-500"
          >
            <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm flex-col md:flex-row">
              <div className="flex-1 items-center mb-4">
                <span className="ml-2 w-0">
                  <b>Wax only.</b> The entire process of how to carve the pieces
                  in wax. &nbsp;
                </span>
              </div>
              <div className="ml-4 flex-shrink-0">
                {loadingBase ? (
                  <Spinny nomt />
                ) : (
                  <button
                    onClick={redirectToCheckoutBase}
                    className="inline-flex items-center justify-center px-5 py-3 border border-transparent
                         text-base font-medium rounded-md text-white bg-blue-700 hover:bg-blue-900"
                  >
                    Buy class €50
                  </button>
                )}
              </div>
            </li>
            <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm flex-col md:flex-row">
              <div className="flex-1 flex items-center mb-4">
                <span className="ml-2 flex-1">
                  <b>Wax and casting clean up.</b> The entire process of how to
                  carve the pieces in wax. <b>PLUS</b> 3 additional videos
                  showing how to clean up the castings <b>AND</b> how to polish
                  and finish them. &nbsp;
                </span>
              </div>
              <div className="ml-4 flex-shrink-0">
                {loadingExtended ? (
                  <Spinny nomt />
                ) : (
                  <button
                    onClick={redirectToCheckoutExtended}
                    className="inline-flex items-center justify-center px-5 py-3 border border-transparent
                         text-base font-medium rounded-md text-white bg-blue-700 hover:bg-blue-900"
                  >
                    Buy class €75
                  </button>
                )}
              </div>
            </li>
          </ul>
        </dd>

        <div className="text-gray-700 rounded-b-lg bg-blue-100 px-6 py-4 sm:px-10 sm:py-4 text-center mx-auto">
          <p>
            Bought the wax only class and want the additional casting clean up
            videos? You can pay the difference to get access to them. The option
            to upgrade is inside the class. <br />
          </p>
        </div>
      </div>
    </div>
  );
};

const ClassSalesPage = ({ pageContext: { page, slug, config, products } }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const bannerImg =
    "http://images.waxcarvers.com.s3-website-eu-west-1.amazonaws.com/waxcarvers.png";
  const imageFile = imageFiles[`./${slug}/${config.image}`]
    ? imageFiles[`./${slug}/${config.image}`].default
    : bannerImg;
  const classInfo = [...overview.classes, ...classes2022].find(
    (it) => kebabCase(it.title) === slug,
  );
  console.log(products);
  const purchaseables = products.filter(
    (it) => kebabCase(it.description) === slug || kebabCase(it.name) === slug,
  );

  console.log(purchaseables);

  const transformImageUri = transformImageUriBuilder(slug);
  const richContent = {
    "@context": "https://schema.org/",
    "@type": "Course",
    name: config.title,
    description: config.description,
    thumbnailUrl: `https://www.waxcarvers.com/${imageFile}`,
    video: {
      "@type": "VideoObject",
      description: config.description,
      name: `${config.title} - Trailer`,
      thumbnailUrl: `https://img.youtube.com/vi/${config.videoUrl.substring(
        config.videoUrl.split("https://youtu.be/")[1],
      )}/0.jpg`,
      uploadDate: classInfo ? classInfo.endDate : new Date().toISOString(),
      contentUrl: config.videoUrl,
    },
    hasCourseInstance: {
      "@type": "CourseInstance",
      courseMode: ["online"],
    },
    provider: {
      "@type": "Organization",
      name: "Wax Carvers",
      logo: {
        "@type": "ImageObject",
        url: bannerImg,
      },
    },
    author: {
      "@type": "Person",
      name: "Sandy Pfaff",
    },
  };

  return (
    <Layout full>
      <Helmet>
        <title>{config.title}</title>
        <meta name="description" content={config.description} />
        <meta property="og:title" content={`${config.title} | Wax Carvers`} />
        <meta property="og:description" content={config.description} />
        <meta
          property="og:image"
          content={`https://www.waxcarvers.com/${imageFile}`}
        />
        <meta
          property="og:url"
          content={`https://www.waxcarvers.com/class/${slug}`}
        />

        <meta itemProp="name" content={`${config.title} | Wax Carvers`} />
        <meta itemProp="description" content={config.description} />

        <meta
          property="twitter:title"
          content={`${config.title} | Wax Carvers`}
        />
        <meta property="twitter:description" content={config.description} />
        <meta
          property="twitter:card"
          content={`https://www.waxcarvers.com/${imageFile}`}
        />
        <meta
          property="twitter:image:src"
          content={`https://www.waxcarvers.com/${imageFile}`}
        />

        <script type="application/ld+json">
          {JSON.stringify(richContent)}
        </script>
      </Helmet>
      <div className="bg-white">
        <div className="mt-12">
          <PageTitle
            title={page.overview.attributes.title}
            subtitle={page.overview.attributes.subtitle}
          />
        </div>
        <main>
          <div className="hidden sm:max-w-3xl sm:mx-auto sm:p-6 sm:block lg:p-8">
            <div className="relative shadow-xl sm:overflow-hidden">
              <div className="absolute inset-0">
                <img
                  src={transformImageUri(page.overview.attributes.image)}
                  alt="Class overview image"
                  className="h-full w-full object-cover"
                />

                <div className="absolute inset-0 mix-blend-multiply"></div>
              </div>
              <div className="relative px-4 py-64 sm:px-6 sm:p-24 lg:p-32 lg:px-8">
                <div className="py-16">
                  <div className="mt-10 lg:flex-shrink-0 mx-12">
                    <div className=" text-center"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="sm:hidden mx-auto sm:p-6">
            <img
              src={transformImageUri(page.overview.attributes.image)}
              alt="Class overview image"
              className="h-full w-full object-cover"
            />
          </div>

          <div className="bg-blue-600 md:mt-4">
            <div className="max-w-4xl mx-auto py-6 px-4 sm:px-4 lg:px-4">
              {page.overview.attributes.asMarkdown ? (
                <p className="text-xl text-white tracking-wide text-left whitespace-pre-wrap">
                  <ReactMarkdown
                    allowDangerousHtml
                    components={{
                      blockquote: ({ node, ...props }) => {
                        return (
                          <span style={{ fontSize: 30 }}>
                            <em {...props} />
                          </span>
                        );
                      },
                    }}
                  >
                    {page && page.overview && page.overview.body
                      ? page.overview.body
                      : ""}
                  </ReactMarkdown>
                </p>
              ) : (
                <p className="text-xl text-white tracking-wide text-left whitespace-pre-wrap">
                  {page && page.overview && page.overview.body
                    ? page.overview.body
                    : ""}
                </p>
              )}
              <div className="lg:flex-shrink-0 mx-12">
                <div className="rounded-md text-center">
                  {classInfo.upgradeable ? (
                    <AnchorLink
                      to="purchase"
                      smooth={true}
                      offset={-100}
                      duration={500}
                    >
                      <div
                        className="mt-16 mb-8 w-48 mx-auto text-base font-bold text-blue-700 hover:text-blue-800 sm:text-lg cursor-pointer
                  md:text-inline-flex items-center justify-center py-3 border border-transparent rounded-md bg-gray-50 hover:bg-blue-100"
                      >
                        Buy Now
                      </div>
                    </AnchorLink>
                  ) : (
                    <SingleBuySection
                      product={purchaseables.find(
                        (it) => it.metadata.type === "class",
                      )}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>

          <Section
            item={{
              title: page.techniques.attributes.title,
              text:
                page && page.techniques && page.techniques.body
                  ? page.techniques.body
                  : "",
              image: transformImageUri(page.techniques.attributes.image),
              imageAlt:
                page.techniques.attributes.imageAlt ??
                page.techniques.attributes.title,
            }}
            transformImageUri={transformImageUri}
            asMarkdown={true}
          />
          <Section
            item={{
              orientation: "right",
              title: page.tools.attributes.title,
              text:
                page && page.tools && page.tools.body ? page.tools.body : "",
              image: transformImageUri(page.tools.attributes.image),
              imageAlt:
                page.tools.attributes.imageAlt ?? page.tools.attributes.title,
            }}
            transformImageUri={transformImageUri}
            asMarkdown={true}
          />
          <Section
            item={{
              title: page.outline.attributes.title,
              text:
                page && page.outline && page.outline.body
                  ? page.outline.body
                  : "",
              image: page.outline.attributes.image
                ? transformImageUri(page.outline.attributes.image)
                : undefined,
              imageAlt:
                page.outline.attributes.imageAlt ??
                page.outline.attributes.title,
              imageComponent:
                config.videoUrl && !page.outline.attributes.image ? (
                  <div className="flex mx-auto rounded-md justify-items-center justify-center object-center mt-12">
                    <ReactPlayer
                      className="self-center"
                      url={config.videoUrl}
                    />
                  </div>
                ) : null,
            }}
            transformImageUri={transformImageUri}
            asMarkdown={true}
          />

          {config.videoUrl && page.outline.attributes.image ? (
            <div className="md:bg-blue-300 py-12 object-fit flex sm:mx-auto justify-items-center justify-center mt-12">
              <ReactPlayer className="" url={config.videoUrl} />
            </div>
          ) : null}

          {classInfo.upgradeable ? (
            <PriceCard purchaseables={purchaseables} />
          ) : (
            <SingleBuySection2
              product={purchaseables.find((it) => it.metadata.type === "class")}
            />
          )}

          <Divider margins={{ t: true, b: true }} />

          <section className="relative bg-white" aria-labelledby="join-heading">
            <div
              className="hidden absolute inset-x-0 h-1/2 bg-blue-gray-50 lg:block"
              aria-hidden="true"
            ></div>
            <div className="max-w-7xl mx-auto  md:bg-transparent lg:px-8">
              <div className="lg:grid lg:grid-cols-12">
                <div className="relative z-10 lg:col-start-1 lg:row-start-1 lg:col-span-4 lg:py-16 lg:bg-transparent">
                  <div
                    className="absolute inset-x-0 h-1/2 bg-blue-gray-50 lg:hidden"
                    aria-hidden="true"
                  ></div>
                  <div className="mx-auto sm:max-w-3xl sm:px-6 lg:max-w-none lg:p-0">
                    <div className="aspect-w-10 aspect-h-8 sm:aspect-w-2 md:aspect-h-2 lg:aspect-w-1 lg:aspect-h-1">
                      <img
                        className="object-cover object-center md:rounded-3xl shadow-2xl"
                        src={photoSmallMe}
                        alt="Photo of me, Sandy."
                      />
                    </div>
                  </div>
                </div>

                <div className="relative bg-blue-600 lg:col-start-3 lg:row-start-1 lg:col-span-10 lg:rounded-3xl lg:grid lg:grid-cols-10 lg:items-center">
                  <div className="relative max-w-md mx-auto py-12 px-4 space-y-6 sm:max-w-3xl sm:py-16 sm:px-6 lg:max-w-none lg:p-0 lg:col-start-4 lg:col-span-6">
                    <h2 className="text-3xl text-white" id="join-heading">
                      Tutor
                    </h2>
                    <p className="text-lg text-white whitespace-pre-wrap">
                      {page && page.tutor ? page.tutor.body : ""}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section>
            <div className="bg-blue-100 lg:py-5 rounded mt-12">
              <div className="max-w-7xl mx-auto px-4 py-12 sm:px-6 lg:px-8 lg:flex lg:items-center lg:justify-center">
                <h2 className="text-2xl text-bold tracking-tight text-gray-700 text-center">
                  <span className="block">Always know what I'm up to!</span>
                </h2>
                <div className="lg:mt-0 lg:flex-shrink-0 mx-12 mt-8">
                  <div className="rounded-md text-center">
                    <a
                      href="https://newsletter.waxcarvers.com"
                      aria-label="Join newsletter"
                      className="inline-flex items-center justify-center px-5 py-3 border border-transparent
                         text-base font-medium rounded-md text-white bg-blue-700 hover:bg-blue-900"
                    >
                      Get weekly emails!
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
    </Layout>
  );
};
export default ClassSalesPage;
